import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbarside from '../Navbarside';
import Loader from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../footer';
import axios from 'axios';
import img1 from '../../img/default.jpeg';
import { Modal, Button, Form ,ListGroup } from 'react-bootstrap';

const PackageDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  localStorage.setItem('LabVal', id);
  const [isLoading, setIsLoading] = useState(false);
  const [packageDetails, setPackageDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [fitnessCenterId, setFitnessCenterId] = useState(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery.length >= 3) {
        fetchSearchResults(searchQuery);
      } else {
        setSearchResults([]); // Clear results if query is less than 3 characters
      }
    }, 300); // Delay of 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const fetchSearchResults = async (query) => {

    try {
      const response = await axios.get(`https://api.healthonify.com/search/fitnessCenter?fcName=${query}`);
      setSearchResults(response?.data?.data); // Assuming `data` contains the array of search results
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://api.healthonify.com/get/package?id=${id}`)
      .then((res) => {
        setIsLoading(false);
        // console.log("test",res.data.data.data);
        setPackageDetails(res?.data?.data?.[0]);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err.message);
      });
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);


  const handleGymSelect = (gym) => {
    setSearchQuery(gym.fcName);
    setFitnessCenterId(gym._id);
    setSearchResults([]);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post("https://api-wm.healthonify.com/admin/associatedGym", {
        packageId: packageDetails?._id,
        fitnessCenterId: fitnessCenterId,
      });
      console.log("Response:", response.data);
      handleCloseModal();
    } catch (error) {
      console.error("Error associating gym:", error);
    }
  };


  // console.log(packageDetails?._id,fitnessCenterId)
  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Package Details</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive ">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-start text">
                              <p>Package Name:</p>
                              <span>{packageDetails?.name}</span>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-start text">
                              <p>Category Name:</p>
                              {packageDetails?.subCategoryId?.name}
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-start text">
                              <p>Price:</p>
                              <span>{packageDetails?.price}</span>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-start text">
                              <p>Duration:</p>
                              <span>{packageDetails?.durationInDays}</span>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-start text">
                              <p>Number of Sessions:</p>
                              <span>{packageDetails?.sessionCount}</span>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-start text">
                              <p>Description:</p>
                              <span>{packageDetails?.description}</span>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-start text">
                              <p>Image:</p>
                              {packageDetails?.mediaLink ? (
                                <img src={packageDetails?.mediaLink} alt="Package" style={{ width: '150px', height: '150px', objectFit: 'cover', marginLeft: '10px' }} />
                              ) : (
                                <img src={img1} alt="Package" style={{ width: '150px', height: '150px', objectFit: 'cover', marginLeft: '10px' }} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center justify-content-between">
                        <h4 className="card-title">Associated Gym</h4>
                        <button className="btn btn-primary" onClick={handleShowModal}>
                          <i className="fa fa-plus"></i> Add
                        </button>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive ">
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            {/* {packageDetails?.associatedGyms?.map((gym, index) => (
                                <div className="d-flex justify-content-between text">
                                <p>Name:</p>
                             <span key={index}>{gym.name}</span>
                             </div>
                                  ))} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Associated Gym</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="gymSearch">
            <Form.Label>Search Gym Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter gym name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {searchQuery?.length >= 3 && searchResults?.length > 0 && (
              <ListGroup>
                {searchResults.map((gym) => (
                  <ListGroup.Item
                    key={gym?._id}
                    action
                    onClick={() => handleGymSelect(gym)}
                    style={{ color: "black" }}
                  >
                    {gym?.fcName}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!fitnessCenterId}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PackageDetails;
