import React from 'react';
import './App.css';
import Home from './component/Home';

import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
//auth
import Login from './component/Login';
import Profile from './component/Profile';
import Register from './component/registration';
import VerifyOtp from './component/verifyOtp';
import Notfoundpage from './component/Not-foundpage';
import ProtectedRoutes from './component/ProtectedRoutes';
import PublicRoutes from './component/PublicRoutes';
import ForgotPassword from './component/ForgotPassword';
import ChangePassword from './component/ChangePassword';

//blog
import Blog from './component/blog/Blog';
import BlogDetails from './component/blog/BlogDetails';
import Addblog from './component/blog/Addblog';
import EditBlogs from "./component/blog/EditBlog";

//community
import Community from './component/community/Community';
import ExpertCommunity from './component/community/ExpertCommunity';
import CommunityDetails from './component/community/CommunityDetails';
import AddCommunity from './component/community/AddCommunity';
import FlaggedCommunityPost from './component/community/FlaggedPost';

//hra
import Questionnaire from './component/hra/Questionnaire';
import AddQuestionnaire from './component/hra/AddQuestionnaire';

//livewell
import LiveWell from './component/liveWell/LiveWell';
// import LiveWellSubCategory from './component/liveWell/LivewellSubCategory';
import ViewPlaylist from './component/liveWell/playlist/ViewPlaylist';
import PlaylistDetail from './component/liveWell/playlist/PlaylistDetail';
import AddPlaylist from './component/liveWell/playlist/AddPlaylist';
import AddCategory from './component/liveWell/AddCategory';
import AddContent from './component/liveWell/content/AddContent';
import Contents from './component/liveWell/content/Contents';

//exercise
import Bodypartgroup from './component/Bodypartgroup';
import Bodyparts from './component/Bodyparts';
import Exercise from './component/Exercise';
import Addbodypart from './component/Addbodypart';
import Editbodypart from './component/Editbodypart';
import Addbodypartgroup from './component/Addbodypartgroup';
import AddExercise from './component/AddExercise';
import EditExercise from './component/EditExercise';
import Workoutplans from './component/Workout-plans';
import Workoutdetails from './component/Workoutdetails';
import ExerciseLibrary from './component/ExerciseLibrary';

//diet
import Recipes from './component/Recipes';
import AddRecipes from './component/AddRecipes';
import EditRecipes from './component/EditRecipes';
import Foodtype from './component/Foodtype';
import Foodcategory from './component/Foodcategory';
import AddFoodcategory from './component/AddFoodcategory';
import AddFoodtype from './component/AddFoodtype';
import Dish from './component/Dish';
import AddDish from './component/AddDish';
import DietPlans from './component/DietPlans';
import DietPlanDetails from './component/DietPlanDetails';
import AddDiet from './component/AddDiet';


//lab
import LabVendorEnquiry from './component/LabVendorEnquiry';
import Labs from './component/Labs';
import AddLabTestCategory from './component/AddLabTestCategory';
import EditLabTestCategory from './component/EditLabTestCategory';
import LabTestcategory from './component/Labtestcategory';
import MyLabs from './component/MyLabs';
import AddLabdetails from './component/AddLabdetails';
import AddLabapprove from './component/AddLabapprove';
import LabOnboardRequest from './component/LabOnboardRequest';
import Labtest from './component/Labtest';
import Labtestdetails from './component/Labtestdetails';
import LabVendorDetails from './component/LabVendorDetails';
import ViewLabs from "./component/ViewLab"
import LabTestOrders from './component/LabTestOrders';

//physio
import Updateconsultphysiotherapy from './component/Updateconsult-physiotherapy';
import Physiotherapy from './component/Physiotherapy';
import Conditions from './component/Conditions';
import ConditionBodyparts from './component/ConditionBodyparts';
import ConditionBodypartgroup from './component/ConditionBodypartgroup';
import PhysioEnquiry from './component/PhysioEnquiry';
import PhysioPackage from './component/PhysioPackage';
import PhysioSession from './component/PhysioSession';
import HealthCareSession from './component/HealthCareSession';
import FitnessSession from './component/FitnessSession';
import LiveWellSession from './component/liveWell/LiveWellSession';
//all enquiry
import Enquiry from './component/Enquiry';
import ViewDetails from './component/enquiry/ViewDetails';
import FitnessGeneralEnquiry from './component/FitnessGeneralEnquiry';

//medical form
import MedicalForm from './component/MedicalForm';
//wm
import Weightmanagement from './component/Weightmanagement';
import FitnessPlans from './component/FitnessPlans';
import Package from './component/package/ListPackage';
import AddFitnessPackage from './component/AddFitnessPackage';

// Expert 
import Experts from './component/Experts';
import MyPatient from './component/MyPatient';
import Active from './component/Active';
import Appointment from './component/Appointment';
import Balance from './component/Balance';
import Batch from './component/Batch';
import Collection from './component/Collection';
import Commission from './component/Commission';
import Payout from './component/Payout';

//LifeSpan

import LifeSpan from './component/LifeSpan';

import AssignHealthCareExpert from './component/AssignHealthCareExpert';
import UpdateLabtestrequest from './component/Update-Labtestrequest';

import MyConsultation from './component/MyConsultation';
import ProcessEnquiry from './component/enquiry/ProcessEnquiry';




import FitnessCenterEnquiry from './component/FitnessCenterEnquiry';
import FitnessCenterRequest from './component/FitnessCenterRequest';
import FitnessrequestApprove from './component/FitnessrequestApprove';
import FitnessCenter from './component/FitnessCenter';
import AddFitnessCenter from './component/AddFitnessCenter';
import FitnessLibrary from './component/FitnessLibrary';

import FitnessPackage from './component/FitnessPackage';
import AssignExpert from './component/AssignExpert';

import WmEnquiry from './component/WmEnquiry';
import Fitness from './component/Fitness';
import HealthCare from './component/HealthCare';
import HcEnquiry from './component/HcEnquiry';
import HcPackage from './component/HcPackage';
import CorporateEnquiry from './component/CorporateEnquiry';
import OnBoardCompany from './component/OnBoardCompany';
import RegisterEmploye from './component/RegisterEmploye';
import VerifyOtpEmploye from './component/VerifyOtpEmploye';
import Changepasswordemploye from './component/Changepasswordemploye';
import Company from './component/Company';
import Corporate from './component/Corporate';
import HealthCareConsultation from './component/HealthCare-Consultation';
import WmConsultation from './component/WmConsultation';
import WmSession from './component/WMSession';
import ListSessions from './component/sessions/ListSession';

import Expertdetails from './component/Expertdetails';
import DoctorAppointment from './component/DoctorAppointment';
import Revenue from './component/Revenue';
import MyCalendar from './component/MyCalendar';
import PatientConsultation from './component/PatientConsultation';
import AddPrescription from './component/AddPrescription';
import Availability from './component/Availability';
import AddExpert from './component/AddExpert';
import LabDetails from './component/LabDetails';
import LabTestRequest from './component/LabTestRequest';
import MyClients from './component/MyClients';
import TodayAppointment from './component/TodayAppointment';
import WmClientConsultation from './component/WmClientConsultation';
import EditProfile from './component/EditProfile';


import Faq from "./component/Faq";
import AddFaq from "./component/AddFaq";
import MyFitnessCenter from './component/MyFitnessCenter';
import AddFitnessPlan from './component/AddFitnessPlan';
import FitnessEnquiry from './component/FitnessEnquiry';
import GeneralEnquiry from './component/enquiry/GeneralEnquiry';
import GeneralHcEnquiry from './component/GeneralHCEnquiry';
import GeneralAyurvedaEnquiry from './component/GeneralAyurvedaEnquiry';
import ClientDetails from './component/ClientDetails';
import FitnessCenterDetails from './component/FitnessCenterDetails';
import AddTestReport from './component/AddTestReport';
import LabVendorRevenue from './component/LabVendorRevenue';
import LabRevenue from './component/LabRevenue';
import EditFitnessPlan from './component/EditFitnessPlan';
import AddPackage from './component/package/AddPackage';
import Analysis from "./component/Analysis";
import SalesAnalysis from "./component/SalesAnalysis";
import AppointmentAnalysis from "./component/AppointmentAnalysis";
import SessionAnalysis from './component/SessionAnalysis';
import MembershipAnalysis from './component/analysis/MembershipAnalysis'
import PackageDetails from './component/package/PackageDetails.jsx'
import FollowUpAnalysis from './component/FollowUpAnalysis';
import EnquiryAnalysis from './component/EnquiryAnalysis';
import ConversionAnalysis from './component/ConversionAnalysis';

import AddWorkoutPlan from "./component/AddWorkoutPlan";
import SalesReport from './component/SalesReport';
import Accounts from './component/Accounts';
import GstRecon from './component/GstRecon';
import ExpertRecon from './component/ExpertRecon';
import EditExpert from './component/EditExpert';
import UserMeasurement from './component/UserMeasurement';

import Challenges from './component/challenge/Challenges';
import AddChallenges from './component/challenge/AddChallenge';
import EditChallenges from './component/challenge/EditChallenge';
import EditContent from './component/liveWell/content/EditContent';

import FitnessCenterPlan from './component/FitnessCenterPlan';
import FitnessCenterRevenue from './component/FitnessCenterRevenue';
import FitnessRevenue from './component/FitnessRevenue';
import FitnessForm from './component/FitnessForm';
import UserWeightLog from './component/UserWeightLog';
import LifeStyle from './component/LifestyleForm';
import LiveWellCategory from './component/liveWell/LiveWellCategory';
import LiveWellPlans from './component/liveWell/LiveWellPlans';
import LiveWellEnquiry from './component/liveWell/LiveWellEnquiry';
import LiveWellGeneralEnquiry from './component/liveWell/LiveWellGeneralEnquiry';
import UserLabReports from './component/UserLabReports';
import UserMedicalHistory from './component/UserMedicalHistory';
import ExpertRecipes from './component/ExpertRecipes';
import ExpertDietPlans from './component/ExpertDietPlans';
import ExpertWorkoutplans from './component/ExpertWorkoutPlans';
import UserHealthLocker from './component/UserHealthLocker';
import UserVitals from './component/UserVitals';
import UserGoals from './component/UserGoals';
import UserDietPlans from './component/UserDietPlans';
import UserWorkoutplans from './component/UserWorkoutPlan';
import WmAppointments from './component/WmAppointments';
import WmExpertSession from './component/WMExpertSession';
import WmGeneralEnquiry from './component/WmGeneralEnquiry';
import Editbodypartgroup from './component/Editbodypartgroup';

import AyurvedaPlan from './component/ayurveda/AyurvedaPlan';
import AddAyurvedaPlan from './component/ayurveda/AddAyurvedaPlan';
import EditAyurvedaPlan from './component/ayurveda/EditAyurvedaPlan';

import PhysiotherapyEnquiry from './component/PhysiotherapyEnquiry';
import AssignPhysiotherapyExpert from './component/AssignPhysiotherapyExpert';
import WomenSpecialSetting from './component/WomenSpecialSetting';
import AddWomenSpecialSetting from './component/AddWomenSpecialSetting';
import EditWomenSpecialSetting from './component/EditWomenSpecialSetting';
import HealthConditions from './component/HealthConditions';
import AddHealthConditions from './component/AddHealthConditions';
import EditHealthConditions from './component/EditHealthConditions';
import WmPackage from './component/WmPackage';
import AddWmPackage from './component/AddWmPackage';
import EditWmPackage from './component/EditWmPackage';
import AddPhysioPackage from './component/AddPhysioPackage';
import AssignSessionExpert from './component/sessions/AssignExpert';
import ViewSubscription from './component/analysis/ViewSubscription';
import Educate from './component/educate';
import ViewEducate from './component/educate/view';
import AddEducate from './component/educate/create';
import FitnessCenterPlanAddEdit from './component/videoPlayer/FitnessCentrePlanAddEdit';
import NotFound from './component/NotFound';
import EditFaq from './component/EditFaq';
import LifeSpanUser from './component/LifeSpanUserList';
function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route exact path='/Home' element={<Home />} />
          <Route exact path='/ViewSubscription/:ticketNumber' element={<ViewSubscription />} />
          <Route exact path='/AddExpert' element={<AddExpert />} />
          <Route exact path='/UserVitals/:id' element={<UserVitals />} />
          <Route exact path='/UserDietPlans/:id' element={<UserDietPlans />} />
          <Route exact path='/UserWorkoutplans/:id' element={<UserWorkoutplans />} />
          <Route exact path='/EditExpert/:id' element={<EditExpert />} />
          <Route exact path='/EditChallenge/:id' element={<EditChallenges />} />
          <Route exact path='/UserGoals/:id' element={<UserGoals />} />
          <Route exact path='/Community/AddCommunity' element={<AddCommunity />} />
          <Route exact path='/ExpertRecipes' element={<ExpertRecipes />} />
          <Route exact path='/WmAppointments' element={<WmAppointments />} />
          <Route exact path='/ExpertWorkoutplans' element={<ExpertWorkoutplans />} />
          <Route exact path='/ExpertDietPlans' element={<ExpertDietPlans />} />
          <Route exact path='/ExpertCommunity' element={<ExpertCommunity />} />
          <Route exact path='/Physiotherapy' element={<Physiotherapy />} />
          <Route exact path='/Physiotherapy/PhysiotherapyEnquiry' element={<PhysiotherapyEnquiry />} />
          <Route exact path='/Physiotherapy/AddPhysiotherapyEnquiry/:id' element={<AssignPhysiotherapyExpert />} />
          <Route exact path='/ProcessEnquiry/:id' element={<ProcessEnquiry />} />
          <Route exact path='/Physiotherapy/HealthConditions' element={<HealthConditions />} />
          <Route exact path='/Physiotherapy/AddHealthConditions' element={<AddHealthConditions />} />
          <Route exact path='/Physiotherapy/EditHealthConditions/:id' element={<EditHealthConditions />} />
          <Route exact path='/WomenSpecialSetting' element={<WomenSpecialSetting />} />
          <Route exact path='/AddWomenSpecialSetting' element={<AddWomenSpecialSetting />} />
          <Route exact path='/EditWomenSpecialSetting/:id' element={<EditWomenSpecialSetting />} />
          <Route exact path='/Conditions' element={<Conditions />} />
          <Route exact path='/Conditions/Bodypartgroup' element={<ConditionBodypartgroup />} />
          <Route exact path='/Conditions/Bodypartgroup/Addbodypartgroup' element={<Addbodypartgroup />} />
          <Route exact path='/Conditions/Bodypartgroup/Editbodypartgroup/:id' element={<Editbodypartgroup />} />
          <Route exact path='/Conditions/Bodyparts' element={<ConditionBodyparts />} />
          <Route exact path='/Conditions/Bodyparts/Addbodypart' element={<Addbodypart />} />
          <Route exact path='/Conditions/Bodyparts/Editbodypart/:id' element={<Editbodypart />} />
          <Route exact path='/Weightmanagement/Recipes' element={<Recipes />} />
          <Route exact path='/Weightmanagement/Package' element={<WmPackage />} />
          <Route exact path='/Weightmanagement/AddPackage' element={<AddWmPackage />} />
          <Route exact path='/Weightmanagement/EditPackage/:id' element={<EditWmPackage />} />
          <Route exact path='/Availability' element={<Availability />} />
          <Route exact path='/FlaggedPost' element={<FlaggedCommunityPost />} />
          <Route exact path='/WmExpertSession' element={<WmExpertSession />} />
          <Route exact path='/FitnessGeneralEnquiry' element={<FitnessGeneralEnquiry />} />
          <Route exact path='/Corporate/CorporateEnquiry' element={<CorporateEnquiry />} />
          <Route exact path='/Corporate/Company/OnBoardCompany' element={<OnBoardCompany />} />
          <Route exact path='/Corporate/Company' element={<Company />} />
          <Route exact path='/Corporate' element={<Corporate />} />
          <Route exact path='/AddDietPlan' element={<AddDiet />} />
          <Route exact path='/HealthCare/HealthCare-Consultation' element={<HealthCareConsultation />} />
          <Route exact path='/HealthCare/AyurvedaPlan' element={<AyurvedaPlan />} />
          <Route exact path='/HealthCare/AddAyurvedaPlan' element={<AddAyurvedaPlan />} />
          <Route exact path='/HealthCare/EditAyurvedaPlan/:id' element={<EditAyurvedaPlan />} />
          <Route exact path='/Weightmanagement/Dish' element={<Dish />} />
          <Route exact path='/Weightmanagement/Dish/AddDish' element={<AddDish />} />
          <Route exact path='/Weightmanagement/WmConsultation' element={<WmConsultation />} />
          <Route exact path='/Weightmanagement/Recipes/AddRecipes' element={<AddRecipes />} />
          <Route exact path='/Recipes/EditRecipes/:id' element={<EditRecipes />} />
          <Route exact path="/ClientDetails/:id" element={<ClientDetails />} />
          <Route exact path="/ViewDetails/:ticketNumber/:flow" element={<ViewDetails />} />
          <Route exact path="/EditContent/:id" element={<EditContent />} />
          <Route exact path="/UserWeightLog/:id" element={<UserWeightLog />} />
          <Route exact path='/Weightmanagement/Foodcategory' element={<Foodcategory />} />
          <Route exact path='/Weightmanagement/Foodtype' element={<Foodtype />} />
          <Route exact path='/Weightmanagement/Foodcategory/AddFoodcategory' element={<AddFoodcategory />} />
          <Route exact path='/Weightmanagement/Foodtype/AddFoodtype' element={<AddFoodtype />} />
          <Route exact path='/FitnessLibrary' element={<FitnessLibrary />} />
          <Route exact path='/Blog' element={<Blog />} />
          <Route exact path='/LabVendorRevenue' element={<LabVendorRevenue />} />
          <Route exact path='/LabRevenue/:id' element={<LabRevenue />} />
          <Route exact path='/UserLabReports/:id' element={<UserLabReports />} />
          <Route exact path='/EditFitnessPlan/:id' element={<EditFitnessPlan />} />
          <Route exact path='/FitnessCenterRequest' element={<FitnessCenterRequest />} />
          <Route exact path='/Fitness/FitnessCenter' element={<FitnessCenter />} />
          <Route exact path='/Fitness/FitnessCenter/AddFitnessCenter' element={<AddFitnessCenter />} />
          <Route exact path='/FitnessrequestApprove/:id' element={<FitnessrequestApprove />} />
          <Route exact path='/FitnessCenterDetails/:id' element={<FitnessCenterDetails />} />
          <Route exact path='Fitness/FitnessPackage' element={<FitnessPackage />} />
          <Route exact path='/FitnessCenterRevenue/:id' element={<FitnessCenterRevenue />} />
          <Route exact path='/UserMedicalHistory/:id' element={<UserMedicalHistory />} />
          <Route exact path='/UserHealthLocker/:id' element={<UserHealthLocker />} />
          <Route exact path='/EditBlog/:id' element={<EditBlogs />} />
          <Route exact path='/UserMeasurement/:id' element={<UserMeasurement />} />
          <Route exact path='/EditProfile' element={<EditProfile />} />
          <Route exact path='/AddChallenge' element={<AddChallenges />} />
          <Route exact path='/Fitness/FitnessCenterEnquiry' element={<FitnessCenterEnquiry />} />
          <Route exact path='/ExerciseLibrary' element={<ExerciseLibrary />} />
          <Route exact path='/AddPackage' element={<AddPackage />} />
          <Route exact path='/Fitness/Bodyparts/Addbodypart' element={<Addbodypart />} />
          <Route exact path='/Fitness/Bodyparts/Editbodypart/:id' element={<Editbodypart />} />
          <Route exact path='/Fitness/Workout-plans' element={<Workoutplans />} />
          <Route exact path='/DietPlans' element={<DietPlans />} />
          <Route exact path='/Fitness/Bodypartgroup/Addbodypartgroup' element={<Addbodypartgroup />} />
          <Route exact path='/Fitness/Bodypartgroup/Editbodypartgroup/:id' element={<Editbodypartgroup />} />
          <Route exact path='/Fitness/Exercise/AddExercise' element={<AddExercise />} />
          <Route exact path='/Fitness/Workout-plans/Workoutdetails/:id' element={<Workoutdetails />} />
          <Route exact path='/FitnessCenterPlan/:id' element={<FitnessCenterPlan />} />
          <Route exact path='/FitnessCenterPlan/add' element={<FitnessCenterPlanAddEdit />} />
          <Route exact path='/FitnessCenterPlan/edit/:id' element={<FitnessCenterPlanAddEdit />} />
          <Route exact path='/LifestyleForm/:id' element={<LifeStyle />} />
          <Route exact path='/FitnessForm/:id' element={<FitnessForm />} />
          <Route exact path='/DietPlanDetails/:id' element={<DietPlanDetails />} />
          <Route exact path='/MedicalForm/:id' element={<MedicalForm />} />
          <Route exact path="/SessionAnalysis" element={<SessionAnalysis />} />
          <Route exact path="/SalesReport" element={<SalesReport />} />
          <Route exact path="/FitnessRevenue" element={<FitnessRevenue />} />
          <Route exact path="/ExpertRecon" element={<ExpertRecon />} />
          <Route exact path="/WmGeneralEnquiry" element={<WmGeneralEnquiry />} />
          <Route exact path="/Challenges" element={<Challenges />} />
          <Route exact path="/GstRecon" element={<GstRecon />} />
          <Route exact path="/Accounts" element={<Accounts />} />
          <Route exact path='/MyClients' element={<MyClients />} />
          <Route exact path='/MyFitnessCenter' element={<MyFitnessCenter />} />
          <Route exact path='/Enquiry' element={<GeneralEnquiry />} />
          <Route exact path='/AddFitnessPlan/:id' element={<AddFitnessPlan />} />
          <Route exact path='/AddTestReport/:id' element={<AddTestReport />} />
          <Route exact path='/Fitness/Exercise' element={<Exercise />} />
          <Route exact path='/Fitness/Bodypartgroup' element={<Bodypartgroup />} />
          <Route exact path='/Fitness/Bodyparts' element={<Bodyparts />} />
          <Route exact path='/MyConsultation' element={<MyConsultation />} />
          <Route exact path="/GeneralHcEnquiry" element={<GeneralHcEnquiry />} />
          <Route exact path='/Updateconsult-physiotherapy' element={<Updateconsultphysiotherapy />} />
          <Route exact path='/HealthCare/AssignHealthCareExpert/:id' element={<AssignHealthCareExpert />} />
          <Route exact path='/Labs/LabTestOrders' element={<LabTestOrders />} />
          <Route exact path='/Labtest' element={<Labtest />} />
          <Route exact path='/Labtest/LabTestRequest/:id' element={<LabTestRequest />} />
          <Route exact path='/Labtest/LabTestRequest/Labtestdetails/:id' element={<Labtestdetails />} />
          <Route exact path='/Update-Labtestrequest' element={<UpdateLabtestrequest />} />
          <Route exact path="/BlogDetails/:id" element={<BlogDetails />} />
          <Route exact path="/Labs" element={<Labs />} />
          <Route exact path="/FitnessEnquiry" element={<GeneralEnquiry />} />
          <Route exact path="/Revenue" element={<Revenue />} />
          <Route exact path="/Package" element={<Package />} />
          <Route exact path="/Physio/PhysioPackage" element={<PhysioPackage />} />
          <Route exact path="/Analysis" element={<Analysis />} />
          <Route exact path="/FitnessPlans" element={<FitnessPlans />} />
          <Route exact path='/Community' element={<Community />} />
          <Route exact path="/GeneralAyurvedaEnquiry" element={<GeneralAyurvedaEnquiry />} />
          <Route exact path='Labs/LabTestcategory' element={<LabTestcategory />} />
          <Route exact path='/Experts' element={<Experts />} />
          <Route exact path='/ListSessions' element={<ListSessions />} />
          <Route exact path='/AssignSessionExpert' element={<AssignSessionExpert />} />
          <Route exact path='/Physiotherapy/PhysioSession' element={<PhysioSession />} />
          <Route exact path='/Fitness/FitnessSession' element={<FitnessSession />} />
          <Route exact path='/HealthCare/HealthCareSession' element={<HealthCareSession />} />
          <Route exact path='/LiveWell/LiveWellSession' element={<LiveWellSession />} />

          <Route exact path='/MyLabs/AddLabdetails' element={<AddLabdetails />} />
          <Route exact path='/MyLabs' element={<MyLabs />} />
          <Route exact path='/AddLabapprove/:id' element={<AddLabapprove />} />
          <Route exact path='Labs/LabOnboardRequest' element={<LabOnboardRequest />} />
          <Route exact path='/Labs/LabTestcategory/AddLabTestCategory' element={<AddLabTestCategory />} />
          <Route exact path='/Labs/LabTestcategory/EditLabTestCategory/:id' element={<EditLabTestCategory />} />
          <Route exact path='/AddPrescription/:id' element={<AddPrescription />} />
          <Route exact path="/CommunityDetails/:id" element={<CommunityDetails />} />
          <Route exact path='/Not-foundpage' element={<Notfoundpage />} />
          <Route exact path="/SalesAnalysis" element={<SalesAnalysis />} />
          <Route exact path="/AppointmentAnalysis" element={<AppointmentAnalysis />} />
          <Route exact path="/MembershipAnalysis" element={<MembershipAnalysis />} />
          <Route exact path="/packageDetails/:id" element={<PackageDetails />} />
          <Route exact path="/FollowUpAnalysis" element={<FollowUpAnalysis />} />
          <Route exact path="/ConversionAnalysis" element={<ConversionAnalysis />} />
          <Route exact path="/EnquiryAnalysis" element={<EnquiryAnalysis />} />

          <Route exact path="/AddWorkoutPlan" element={<AddWorkoutPlan />} />
          <Route exact path='/Addblog' element={<Addblog />} />
          <Route path="/" element={<Navigate replace to="home" />} />
          <Route exact path='/Profile' element={<Profile />} />
          <Route exact path='/MyCalendar' element={<MyCalendar />} />
          <Route exact path='/MyLabs/LabDetails/:id' element={<LabDetails />} />
          <Route exact path='/MyLabs/LabVendorDetails/:id' element={<LabVendorDetails />} />
          <Route exact path='/MyLabs/LabVendorDetails/ViewLab/:id' element={<ViewLabs />} />
          <Route exact path='/Active' element={<Active />} />
          <Route exact path='/Appointment' element={<Appointment />} />
          <Route exact path='/Balance' element={<Balance />} />
          <Route exact path='/Batch' element={<Batch />} />
          <Route exact path='/Collection' element={<Collection />} />
          <Route exact path='/DoctorAppointment' element={<DoctorAppointment />} />
          <Route exact path='/TodayAppointment' element={<TodayAppointment />} />
          <Route exact path='/Commission' element={<Commission />} />
          <Route exact path='/Payout' element={<Payout />} />
          <Route exact path='/Enquiry' element={<Enquiry />} />
          <Route exact path='/LiveWell' element={<LiveWell />} />
          <Route exact path='/LiveWellCategory' element={<LiveWellCategory />} />
          <Route exact path='/LiveWellPlans' element={<LiveWellPlans />} />
          <Route exact path='/LiveWellEnquiry' element={<LiveWellEnquiry />} />
          <Route exact path='/LiveWellGeneralEnquiry' element={<LiveWellGeneralEnquiry />} />
          <Route exact path='/LiveWell/AddCategory' element={<AddCategory />} />
          <Route exact path='/Labs/LabVendors' element={<LabVendorEnquiry />} />
          <Route exact path="/EditExercise/:id" element={<EditExercise />} />
          <Route exact path="/PatientConsultation/:id" element={<PatientConsultation />} />
          <Route exact path="/WmClientConsultation/:id" element={<WmClientConsultation />} />
          <Route exact path='/register/VerifyOtp' element={<VerifyOtp />} />
          <Route exact path="/MyPatient" element={<MyPatient />} />
          <Route exact path="/Questionnaire" element={<Questionnaire />} />
          <Route exact path="/Questionnaire/AddQuestionnaire" element={<AddQuestionnaire />} />
          <Route exact path="/Weightmanagement" element={<Weightmanagement />} />
          <Route exact path="/Weightmanagement/WmEnquiry" element={<WmEnquiry />} />
          <Route exact path="/AssignExpert:id" element={<AssignExpert />} />
          <Route exact path="/Expertdetails/:id" element={<Expertdetails />} />
          <Route exact path="/Fitness" element={<Fitness />} />
          <Route exact path="Fitness/AddFitnessPackage" element={<AddFitnessPackage />} />

          <Route exact path="/Faq" element={<Faq />} />
          <Route exact path="/AddFaq" element={<AddFaq />} />
          <Route exact path="/editFaq/:id" element={<EditFaq   />} />
          <Route exact path="/HealthCare" element={<HealthCare />} />
          <Route exact path="/HealthCare/HcEnquiry" element={<HcEnquiry />} />
          <Route exact path="/HealthCare/HcPackage" element={<HcPackage />} />
          <Route exact path="/Physiotherapy/PhysioEnquiry" element={<PhysioEnquiry />} />
          <Route exact path="/Physiotherapy/AddPhysioPackage" element={<AddPhysioPackage />} />

          <Route exact path="/Weightmanagement/WmSession" element={<WmSession />} />

         {/* LifeSpan */}
          <Route exact path="/LifeSpan" element={<LifeSpan />} />
          <Route exact path="/LifeSpan/User" element={<LifeSpanUser />} />
          
        </Route>

        <Route path="login" element={<PublicRoutes />}>
       
          <Route exact path="/login" element={<Login />} />
        </Route>

        <Route exact path='/register/VerifyOtp' element={<VerifyOtp />} />
        <Route exact path='/register/VerifyOtpEmploye' element={<VerifyOtpEmploye />} />
        <Route exact path='/register' element={<Register />} />
        <Route exact path='/RegisterEmploye' element={<RegisterEmploye />} />
        <Route exact path='/ForgotPassword' element={<ForgotPassword />} />
        <Route exact path='/ChangePassword' element={<ChangePassword />} />
        <Route exact path='/Changepasswordemploye' element={<Changepasswordemploye />} />

        <Route exact path='/LiveWell/Contents/AddContent/:id' element={<AddContent />} />
        <Route exact path='/LiveWell/Contents/AddContent' element={<AddContent />} />
        <Route exact path='/LiveWell/Playlist/Add' element={<AddPlaylist />} />
        <Route exact path='/LiveWell/Playlist/Edit' element={<AddPlaylist />} />
        <Route exact path="/LiveWell/Contents/:id" element={<Contents />} />
        <Route exact path="/LiveWell/Playlist" element={<ViewPlaylist />} />

        <Route exact path="/Playlist/view" element={<ViewPlaylist />} />
        <Route exact path="/Playlist/view/:id" element={<PlaylistDetail />} />

        <Route exact path='/educate' element={<Educate />} />
        <Route exact path='/educate/add' element={<AddEducate />} />
        <Route exact path='/educate/edit/:id' element={<AddEducate />} />
        <Route exact path='/educate/view/:id' element={<ViewEducate />} />
        <Route path='*' element = {<NotFound/>} />





      </Routes>
    </Router>

  );
}

export default App;